/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';
import { Row, Button, Hero, Features, Testimonial } from 'components/lib';
import ImgDashboard from './images/dashboard.png';
import ImgKyleGawley from './images/kyle-gawley.jpg';

export function Home(props){

  return(
    <Fragment>

      <Hero
        title='Datastand'
      />
    </Fragment>
  );
}
